import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=5035df78&"
import script from "./ErrorPage.ts?vue&type=script&lang=ts&"
export * from "./ErrorPage.ts?vue&type=script&lang=ts&"
import style0 from "./ErrorPage.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../../../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QPageContainer});
